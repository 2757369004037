import logo from './logo.svg';
import './Hero.css';

export default function Hero() {
  return (
    <div className="Hero">
      <header className="Hero-header">
        <img src={logo} className="Hero-logo" alt="logo" />
        <p>
          <a className="Hero-link" href='https://github.com/halitaydin' rel='noopener noreferrer' target="_blank">github</a>&nbsp;&#183;&nbsp;<a className="Hero-link" href='https://www.linkedin.com/in/halit-aydin' rel='noopener noreferrer' target="_blank">linkedin</a>&nbsp;&#183;&nbsp;halit@halit.io
        </p>
      </header>
    </div>
  );
}
