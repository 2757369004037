import * as THREE from 'three';
import { Vector3 } from 'three'
import { Html, OrbitControls, SpotLight, useGLTF } from "@react-three/drei"
import { useRef } from "react"
import { useThree, useFrame } from '@react-three/fiber'
import Hero from "./Hero"

export default function Gameboy() {
  const gameboy = useGLTF('./gameboy.glb')

  // screen reflection remove
  gameboy.nodes.Scene.children[0].children[3].material = new THREE.MeshBasicMaterial({ color: "#282c34" })

  return (
    <>
    <OrbitControls makeDefault />
      <MovingSpot color="#b00c3f" position={[-1, -1, 4]} angle={0.55}/>
      <MovingSpot color="#0c8cbf" position={[-4, -1, -1]} />
      <MovingSpot color="#0c8cbf" position={[1, -1, -4]} />
      <MovingSpot color="#0c8cbf" position={[4, -1, 1]} />
      <MovingSpot color="#0c8cbf" position={[0, 3, 3]} angle={0.55}/>
      <primitive  object={gameboy.scene} scale={30} position-y={-2} dispose={null}>
        <Html
        rotation-y={0.55}
        transform
        wrapperClass="htmlScreen"
        distanceFactor={0.04}
        position={[ 0.008, 0.083, 0.012 ]}
        occlude
        >
          <Hero />
        </Html>
      </primitive>
    </>
  )
}

function MovingSpot({ vec = new Vector3(), ...props }) {
    const light = useRef()
    const viewport = useThree((state) => state.viewport)
    useFrame((state) => {
      light.current.target.position.lerp(vec.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 0), 0.1)
      light.current.target.updateMatrixWorld()
    })
    return <SpotLight castShadow ref={light} penumbra={1} distance={6} angle={0.4} attenuation={5} anglePower={4} intensity={8} {...props} />
  }
