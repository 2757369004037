import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Gameboy from './Gameboy.jsx'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <Canvas dpr={[1, 2]} camera={{ position: [-1, 2, 6], fov: 50, near: 1, far: 20 }}>
      <color attach="background" args={['#000000']} />
        <Gameboy />
    </Canvas>
)
